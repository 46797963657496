import React, { createContext, useContext, useState } from 'react';
import { YesNoModalComponent, YesNoModalProps } from './yes-no-modal.component';


export interface ModalContextProps {
    showModal: (modalProps: YesNoModalProps) => void;
    hideModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [modalProps, setModalProps] = useState<YesNoModalProps | null>(null);

    const showModal = (props: YesNoModalProps) => {
        setModalProps(props);
    };

    const hideModal = () => {
        setModalProps(null);
    };

    return (
        <ModalContext.Provider value={{ showModal, hideModal }}>
            {children}
            {modalProps && (
                <YesNoModalComponent
                    title={modalProps.title}
                    description={modalProps.description}
                    onYesClicked={() => {
                        modalProps.onYesClicked();
                        hideModal();
                    }}
                    onNoClicked={() => {
                        modalProps.onNoClicked();
                        hideModal();
                    }}
                />
            )}
        </ModalContext.Provider>
    );
};
