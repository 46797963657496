import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import jsonpath from "jsonpath";
import { post } from "../infrastructure/http-client";
import { PlunderDataEntityDetailsDto } from "../data-dtos/plunder-data-entity-details-dto";
import { ObjectDefinitionDto } from "../data-dtos/object-definition-dto";
import { useTheme } from "@emotion/react";
import { ObjectRendererComponent } from "./object-renderer.component";
import { ObjectMemberDefinitionDto } from "../data-dtos/object-member-definition-dto";
import { DataTypeEnum } from "../data-dtos/data-type-enum";
import { JsonEditor } from "json-edit-react";
import jp from "jsonpath";

import SaveIcon from "@mui/icons-material/Save";

interface IProps {
  definition: ObjectDefinitionDto[];
  entity: PlunderDataEntityDetailsDto;
  onSave: () => void;
}

const borderStyle = {
  border: "solid 2px rgba(255, 255, 255, 0.2)",
  borderRadius: 1,
};

export const EntityEditorComponent: React.FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const [showRawData, setShowRawData] = useState(false);

  const [currentModel, setCurrentModel] = useState(
    JSON.parse(props.entity.details)
  );

  const [savingState, setSavingState] = useState<{
    isSaving: boolean;
    error?: string;
    success?: boolean;
  }>({ isSaving: false });

  useEffect(() => {
    setCurrentModel(JSON.parse(props.entity.details));
  }, [props.entity]);

  const saveModel = async (id: string, category: string) => {
    // console.log(currentModel);
    setSavingState({ isSaving: true });

    try {
      await post(
        `api/Server/Data/UpdateDataEntity`,
        {
          Id: id,
          Category: category,
          Details: `${JSON.stringify(currentModel)}`
        }
      );
      setTimeout(() => {
        setSavingState({ isSaving: false, error: undefined, success: true });
      }, 500);
    } catch (exception) {
      if (exception instanceof Error) {
        setSavingState({ isSaving: false, error: exception.message });
      } else {
        // If the error isn't an instance of Error, you can handle it accordingly
        setSavingState({
          isSaving: false,
          error: "An unknown error occurred.",
        });
      }
    }

    props.onSave();
  };

  const applyChangeToModel = (val: any, jsonPath: string) => {
    let clone = { ...currentModel };
    jsonpath.value(clone, jsonPath, val);
    setCurrentModel(clone);
  };

  const addEntryToCollection = (objectDefinition: ObjectDefinitionDto, jsonPath: string) => {
    debugger;
    let clone = { ...currentModel };
    let array = (jsonpath.value(clone, jsonPath) as any[]) ?? [];

    if (!Array.isArray(array)) array = [];
    array.push(JSON.parse(objectDefinition.defaultValue));
    jsonpath.value(clone, jsonPath, array);
    setCurrentModel(clone);
  };

  const removeEntryFromCollection = (jsonPath: string, index: number) => {
    let clone = { ...currentModel };
    let array = (jsonpath.value(clone, jsonPath) as any[]) ?? [];
    array = array.filter((x, i) => i != index);
    jsonpath.value(clone, jsonPath, array);
    setCurrentModel(clone);
  };

  const getValueFromModel = (path: any) => {
    return jsonpath.query(currentModel, path)[0];
  };

  const renderInput = (
    definition: ObjectDefinitionDto,
    val: string,
    onChange: (val: any) => void
  ) => {};

  const lowercaseFirstCharacter = (str: string): string => {
    if (!str) return ""; // Return an empty string if the input is empty
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const getInitialValue = (definition: ObjectMemberDefinitionDto) => {
    var objDefinition = props.definition.find(
      (x) => x.name == definition.objectDefinitionName
    );

    if (definition.isCollection) return [];

    if (objDefinition?.dataTypeEnum == DataTypeEnum.Object) return {};
    if (objDefinition?.dataTypeEnum == DataTypeEnum.String) return "";
    if (objDefinition?.dataTypeEnum == DataTypeEnum.Number) return 0;
    if (objDefinition?.dataTypeEnum == DataTypeEnum.Enum)
      return objDefinition.enumValues[0];
  };

  return (
    <Grid container direction="column" gap={1}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={showRawData}
              onChange={(e) => setShowRawData(e.target.checked)}
            />
          }
          label="Show raw data"
        />
      </Grid>
      <Grid item sx={{  }}>
        {showRawData && (
          <>
            <JsonEditor
              theme="githubDark"
              maxWidth="100%"
              data={currentModel}
            />
          </>
        )}
        {!showRawData && (
          <ObjectRendererComponent
            definitions={props.definition}
            member={props.definition[0]}
            model={currentModel}
            name=""
            onAddEntry={(definition, path) => addEntryToCollection(definition, path)}
            onRemoveEnty={(path, index) =>
              removeEntryFromCollection(path, index)
            }
            onPropertyAdd={(definition, path) => {
              applyChangeToModel(
                getInitialValue(definition),
                `${path}.${definition.name}`
              );
            }}
            path="$"
            expanded
            value={currentModel}
            onPropertyValueChanged={(val, path) => {
              applyChangeToModel(val, path);
            }}
          />
        )}
      </Grid>
      <div style={{ position: "fixed", bottom: 10, right: 10 }}>
        <Paper>
          <Grid container p={1} direction="row" gap={1} alignItems="center">
            <Grid item>
              {savingState.success && !savingState.success && !savingState.isSaving && <Typography variant="caption" color="error">
                {savingState.error ?? "Unknown error occured"}
              </Typography>}
              {savingState && savingState.success && <Typography variant="caption" color="succes">
                Success
              </Typography>}
            </Grid>
            
            <Grid item>
              <Button
                disabled={savingState.isSaving}
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={() =>
                  saveModel(
                    props.entity.id,
                    props.entity.category
                  )
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Grid>
  );
};
