import React from "react";
import { ObjectDefinitionDto } from "../data-dtos/object-definition-dto";
import { DataTypeEnum } from "../data-dtos/data-type-enum";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";

export interface IProps {
  definition: ObjectDefinitionDto;
  val: any;
  onChange: (val: any) => void;
}

export const InputRendererComponent: React.FC<IProps> = (props) => {
  if (props.definition.dataTypeEnum == DataTypeEnum.String) {
    return (
      <TextareaAutosize
        //   size="small"
        //   variant="standard"
        value={props.val}
        //   fullWidth
        //   rows={3}
        style={{
          width: "100%",
          paddingTop: 10,
          paddingBottom: 10,
          border: "none",
          borderBottom: "solid 1px white",
          color: "white",
          backgroundColor: "transparent",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
    );
  }
  if (props.definition.dataTypeEnum == DataTypeEnum.Number) {
    return (
      <TextField
        size="small"
        variant="standard"
        type="number"
        value={props.val}
        fullWidth
        onChange={(e) => props.onChange(e.target.value)}
      />
    );
  }
  if (props.definition.dataTypeEnum == DataTypeEnum.Bool) {
    return (
      <Checkbox
        size="small"
        checked={props.val}
        onChange={(e) => props.onChange(e.target.checked)}
      />
    );
  }

  if (props.definition.dataTypeEnum == DataTypeEnum.Enum) {
    return (
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          value={props.val}
        >
          {props.definition.enumValues.map((e) => (
            <MenuItem key={e.key} value={e.key}>
              {e.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Typography color="error">
      Unsupported type {props.definition.dataTypeEnum}
    </Typography>
  );
};
