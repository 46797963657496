import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { List, ListRowProps, ListRowRenderer } from "react-virtualized";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";

import { get, post } from "../infrastructure/http-client";

import { NpcDataDto } from "../data-dtos/npc-data-dto";
import { EntityEditorComponent } from "../components/entity-editor.component";
import { PlunderDataEntityDetailsDto } from "../data-dtos/plunder-data-entity-details-dto";
import { PlunderDataEntityDto } from "../data-dtos/plunder-data-entity-dto";
import { ObjectDefinitionDto } from "../data-dtos/object-definition-dto";
import { CreateEntityComponent } from "../components/create-entity.component";
import { EntityListRendererComponent } from "../components/entity-list-renderer.component";

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export const EntitiesContext = createContext<PlunderDataEntityDto[]>([]);

export const EditorPage: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState("");

  let timeoutId: any;

  const [createEntityCategory, setCreateEntityCategory] = useState<
    { category: string; idSuggestion: string } | undefined
  >(undefined);

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [categories, setCategories] = useState<string[]>([]);
  const [dataEntities, setDataEntities] = useState<PlunderDataEntityDto[]>([]);
  const [filteredDataEntities, setFilteredDataEntities] = useState<
    PlunderDataEntityDto[]
  >([]);

  const [currentDefinition, setCurrentDefinition] = useState<
    ObjectDefinitionDto[] | undefined
  >(undefined);
  const [currentModel, setCurrentModel] = useState<
    PlunderDataEntityDetailsDto | undefined
  >(undefined);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getDataEntities();
  }, []);

  const onFilterInputChanged = (val: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setFilter(val);
    }, 150);
  };

  useEffect(() => {
    setFilteredDataEntities(
      dataEntities.filter(
        (x) => !filter || x.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
    console.log("filtering");
  }, [filter, dataEntities]);

  useEffect(() => {
    setCategories(Array.from(new Set(dataEntities.map((x) => x.category))));
  }, [dataEntities]);

  const getDataEntities = async () => {
    setShowLoading(true);
    const dataEntities = await get<PlunderDataEntityDto[]>(
      "api/Server/Data/GetDataEntities"
    );
    setDataEntities(dataEntities);
    setShowLoading(false);
  };

  const saveDataEntityName = async (
    id: string,
    category: string,
    name: string,
  ) => {
    await post(
      `api/Server/Data/UpdateDataEntity`,
      {
        id: id,
        category: category,
        name: name
      }
    );

    getDataEntities();
  };

  const saveDataEntityChecked = async (
    dataEntity: PlunderDataEntityDto,
    checked: boolean
  ) => {
    await post(
      `api/Server/Data/UpdateDataEntity`,
      {
        id: dataEntity.id,
        category: dataEntity.category,
        enabled: checked,
      }
    );
    getDataEntities();
  };

  const deleteEntity = async (dataEntity: PlunderDataEntityDto) => {
    await post(
      `api/Server/Data/DeleteEntityData`,
      {
        id: dataEntity.id,
        category: dataEntity.category
      }
    );
    getDataEntities();
  };

  const loadEditor = async (dataEntity: PlunderDataEntityDto) => {
    setShowLoading(true);
    try {
      let startTime = Date.now();

      get<PlunderDataEntityDetailsDto>(
        `api/Server/Data/GetEntityData?category=${dataEntity.category}&id=${dataEntity.id}`
      ).then((r: PlunderDataEntityDetailsDto) => {
        setCurrentModel(r);
        // console.log(
        //   JSON.parse(r.details),
        //   `Loading time: ${Date.now() - startTime} ms`
        // );
      });

      get<ObjectDefinitionDto[]>(
        `api/Server/Data/GetDataEntitySchemaDefinition?dtoType=${dataEntity.type}`
      ).then((r: ObjectDefinitionDto[]) => {
        setCurrentDefinition(r);
        console.log(`Loading time: ${Date.now() - startTime} ms`);
      });
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setShowLoading(false);
    }
  };

  const filterDataEntitiesForCategory = (
    category: string
  ): PlunderDataEntityDto[] => {
    return dataEntities.filter((x) => x.category === category);
  };

  const onCreate = async (
    category: string,
    id: string,
    name: string,
    type: string
  ) => {
    await post(`api/Server/Data/CreateDataEntity`, {
      id: id,
      name: name,
      category: category,
      type: type,
    });

    getDataEntities();
    setCreateEntityCategory(undefined);
  };

  const renderEntity = (d: PlunderDataEntityDto, i: number, style: any) => {
    return (
      <Grid item key={i} style={{ ...style }}>
        <ListItemButton
          selected={
            currentModel?.category == d.category && currentModel?.id == d.id
          }
          onClick={() => loadEditor(d)}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>{d.name}</Grid>
            <Grid item>
              <Tooltip title="Enabled?">
                <Checkbox
                  size="small"
                  // onClick={(e) => e.stopPropagation()}
                  defaultChecked={d.enabled}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton size="small" color="error">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </ListItemButton>
      </Grid>
    );
  };

  const renderList = (): any => {
    if (filter != "") return renderFilteredList();
    if (filter == "") return renderNonFilteredList();
  };

  const renderNonFilteredList = () => {
    return categories.map((x, i) => {
      const dataEntitiesForCategory = filterDataEntitiesForCategory(x);
      return (
        <EntityListRendererComponent
          key={i}
          category={x}
          onCreateEntityClicked={(create) => setCreateEntityCategory(create)}
          dataEntitiesForCategory={dataEntitiesForCategory}
          onEntityClicked={(data) => loadEditor(data)}
          currentModel={currentModel}
          onChecked={(dataEntity, checked) =>
            saveDataEntityChecked(dataEntity, checked)
          }
          onNameChanged={(id, category, name) => saveDataEntityName(id, category, name)}
          onDeleteClicked={(dataEntity) => deleteEntity(dataEntity)}
        />
      );
    });
  };

  const renderFilteredList = () => {
    return categories
      .filter((x) => filteredDataEntities.some((de) => de.category == x))
      .map((x, i) => {
        const dataEntitiesForCategory = filteredDataEntities.filter(
          (de) => x == de.category
        );
        return (
          <EntityListRendererComponent
            key={i}
            category={x}
            onCreateEntityClicked={(create) => setCreateEntityCategory(create)}
            dataEntitiesForCategory={dataEntitiesForCategory}
            onEntityClicked={(data) => loadEditor(data)}
            currentModel={currentModel}
            onNameChanged={(id, category, name) => {
              saveDataEntityName(id, category, name);
            }}
            onChecked={(dataEntity, checked) =>
              saveDataEntityChecked(dataEntity, checked)
            }
            onDeleteClicked={(dataEntity) => deleteEntity(dataEntity)}
            collapsed={true}
          />
        );
      });
    // const listEntry = (props: ListRowProps) => {
    //   var data = filteredDataEntities[props.index];
    //   return renderEntity(data, props.index, props.style);
    // };

    // return categories
    //   .filter((c) => filteredDataEntities.some((x) => x.category == c))
    //   .map((x) => {
    //     return (
    //       <Accordion key={x} disableGutters expanded>
    //         <AccordionSummary aria-controls="panel1-content" id="panel1-header">
    //           <Grid container direction="row" alignItems="center">
    //             <Grid item xs>
    //               <Typography variant="caption">{x}</Typography>
    //             </Grid>
    //           </Grid>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <Grid container direction="column" justifyContent="stretch">
    //             {/* {filteredDataEntities
    //               .filter((d) => d.category == x)
    //               .map((d, i) => renderEntity(d, i))} */}
    //             <List
    //               width={310}
    //               height={400}
    //               rowHeight={50}
    //               rowCount={filteredDataEntities.length}
    //               rowRenderer={listEntry}
    //             />
    //           </Grid>
    //         </AccordionDetails>
    //       </Accordion>
    //     );
    //   });
  };

  return (
    <>
      <EntitiesContext.Provider value={dataEntities}>
        <Box sx={{ display: "flex" }}>
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Plunder database editor
              </Typography>
              <Button
                variant="text"
                startIcon={<LogoutIcon />}
                sx={{ marginLeft: "auto" }} // This pushes the button to the right
                onClick={() => {
                  localStorage.setItem("subscription-key", "");
                  (window as any).location = "/login";
                }}
              >
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <TextField
              id="search-data-entity"
              variant="filled"
              placeholder="Search..."
              onChange={(e) => onFilterInputChanged(e.target.value)}
            />
            <Divider />

            {renderList()}
          </Drawer>
          {showLoading && (
            <Box
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              width="100vw"
              rowGap={1}
              style={{ position: "absolute", zIndex: 999 }}
            >
              <CircularProgress />
              <Typography>Connecting to server...</Typography>
            </Box>
          )}
          <Main open={open}>
            <DrawerHeader />
            {currentDefinition && currentModel && (
              <>
                <EntityEditorComponent
                  definition={currentDefinition}
                  entity={currentModel}
                  onSave={() => {}}
                />
              </>
            )}
          </Main>
        </Box>
        {createEntityCategory && (
          <CreateEntityComponent
            idSuggestion={createEntityCategory.idSuggestion}
            category={createEntityCategory.category}
            onCreate={(id, name, type) =>
              onCreate(createEntityCategory.category, id, name, type)
            }
            onCancel={() => setCreateEntityCategory(undefined)}
            types={Array.from(
              new Set(
                dataEntities
                  .filter((x) => x.category == createEntityCategory.category)
                  .map((x) => x.type)
              )
            )}
          />
        )}
      </EntitiesContext.Provider>
    </>
  );
};
