import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  IconButton,
  ListItemButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { PlunderDataEntityDto } from "../data-dtos/plunder-data-entity-dto";
import React, { FC, useState } from "react";
import { List, ListRowProps, AutoSizer } from "react-virtualized";
// import AutoSizer from "react-virtualized-auto-sizer"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

import { PlunderDataEntityDetailsDto } from "../data-dtos/plunder-data-entity-details-dto";
import { useModal } from "./shared/yes-no-modal.provider";
import { post } from "../infrastructure/http-client";

interface IProps {
  category: string;
  dataEntitiesForCategory: PlunderDataEntityDto[];
  onCreateEntityClicked: (createEntity: {
    category: string;
    idSuggestion: string;
  }) => void;
  onDeleteClicked: (data: PlunderDataEntityDto) => void;
  onEntityClicked: (data: PlunderDataEntityDto) => void;
  currentModel?: PlunderDataEntityDetailsDto;
  onChecked: (data: PlunderDataEntityDto, checked: boolean) => void;
  onNameChanged: (id: string, category: string, name: string) => void;
  collapsed?: boolean;
}

interface EditState {
  index: number;
  edit: boolean;
  name: string;
}

export const EntityListRendererComponent: FC<IProps> = (props) => {
  const { showModal } = useModal();

  const [editStates, setEditStates] = useState<EditState[]>([]);

  const addEdit = (index: number, edit: boolean, name: string) => {
    var clone = [...editStates];
    var current = clone.find((x) => x.index == index);
    if (current) {
      current.edit = edit;
    } else {
      clone.push({ index: index, edit: edit, name: name });
    }

    setEditStates(clone);
  };

  const changeNameState = (name: string, index: number) => {
    var clone = [...editStates];
    var entry = clone.find((x) => x.index == index);

    if (entry) {
      entry.name = name;
    }

    setEditStates(clone);
  };

  const clearEdit = (index: number) => {
    var clone = [...editStates];
    setEditStates(clone.filter((x) => index != x.index));
  };

  const finishEdit = async (id: string, name: string, index: number) => {
    props.onNameChanged(id, props.category, name);
    clearEdit(index);
  };

  const renderEntity = (d: PlunderDataEntityDto, i: number) => {
    return (
      <Grid item key={i}>
        <ListItemButton
        //   selected={
        //     currentModel?.category == d.category && currentModel?.id == d.id
        //   }
        //   onClick={() => loadEditor(d)}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs>
              {d.name}
            </Grid>
            <Grid item>
              <Tooltip title="Enabled?">
                <Checkbox
                  size="small"
                  // onClick={(e) => e.stopPropagation()}
                  defaultChecked={d.enabled}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  showModal({
                    title: "Confirm Delete",
                    description: "Are you sure you want to delete this item?",
                    onYesClicked: () => {
                      console.log("Item deleted");
                      props.onDeleteClicked(d);
                    },
                    onNoClicked: () => {
                      console.log("Delete canceled");
                    },
                  });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </ListItemButton>
      </Grid>
    );
  };

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    const data = props.dataEntitiesForCategory[index];
    const editState = editStates.find((x) => x.index == index);
    var isEditing = editState?.edit ?? false;
    return (
      <Grid item key={index} style={{ ...style }}>
        <ListItemButton
          selected={
            props.currentModel?.category == data.category &&
            props.currentModel?.id == data.id
          }
          onClick={() => props.onEntityClicked(data)}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs>
              {!isEditing && (
                <Typography variant="caption">{data.name}</Typography>
              )}
              {isEditing && (
                <TextField
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    // props.onEntityClicked(data);
                  }}
                  onChange={(e) => changeNameState(e.target.value, index)}
                  variant="standard"
                  value={editState?.name}
                />
              )}
            </Grid>
            <Grid item>
              {isEditing ? (
                <>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      finishEdit(data.id, editState!.name, index);
                    }}
                  >
                    <DoneIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      clearEdit(index);
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    addEdit(index, !editState?.edit ?? true, data.name);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <Tooltip title="Enabled?">
                <Checkbox
                  size="small"
                  onChange={(e) => {
                    props.onChecked(data, e.target.checked);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  defaultChecked={data.enabled}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  showModal({
                    title: `Confirm Delete`,
                    description:
                      `Are you sure you want to delete  ${data.name}?`,
                    onYesClicked: () => {
                      props.onDeleteClicked(data);
                    },
                    onNoClicked: () => {},
                  });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </ListItemButton>
      </Grid>
    );
  };

  return (
    <Accordion disableGutters expanded={props.collapsed}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            {props.category}
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                const idSuggestion = props.dataEntitiesForCategory.reduce(
                  (max, entity) => {
                    return entity.id > max.id ? entity : max;
                  }
                );

                props.onCreateEntityClicked({
                  category: props.category,
                  idSuggestion: idSuggestion.id,
                });
              }}
            >
              <AddIcon fontSize="small" color="success" />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {/* <Grid container direction="column" justifyContent="stretch"> */}
        {/* {dataEntitiesForCategory.map((d, i) => renderEntity(d, i))} */}
        <AutoSizer style={{ height: 500 }}>
          {({ width, height }) => (
            <List
              id={props.category}
              key={props.category}
              width={width}
              height={height}
              rowHeight={60}
              rowCount={props.dataEntitiesForCategory.length}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
        {/* </Grid> */}
      </AccordionDetails>
    </Accordion>
  );
};
