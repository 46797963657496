import React, { useEffect, useState } from "react";
import { ObjectDefinitionDto } from "../data-dtos/object-definition-dto";
import { DataTypeEnum } from "../data-dtos/data-type-enum";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ObjectDefinitionRenderer } from "./object-definition-renderer.component";
import { ObjectMemberDefinitionDto } from "../data-dtos/object-member-definition-dto";
import { useModal } from "./shared/yes-no-modal.provider";

export interface IProps {
  definitions: ObjectDefinitionDto[];
  name: string;
  path: string;
  value: any;
  member: ObjectDefinitionDto;
  model: any;
  expanded?: boolean;
  onAddEntry: (definition: ObjectDefinitionDto, path: string) => void;
  onRemoveEnty: (path: string, index: number) => void;
  onPropertyAdd: (objectType: ObjectMemberDefinitionDto, path: string) => void;
  onPropertyValueChanged: (val: any, path: string) => void;
}

const borderStyle = {
  border: "solid 2px rgba(255, 255, 255, 0.2)",
  borderRadius: 1,
};

export const ObjectRendererComponent: React.FC<IProps> = (props) => {
  const { showModal } = useModal();

  const [selectedObjectDefinition, setselectedObjectDefinition] = useState<
    ObjectMemberDefinitionDto | undefined
  >();

  const childMembersThatCanBeAdded = props.member.members.filter(
    (x) => !props.value || props.value[x.name] == null
  );

  return (
    <Box mt={1}>
      <Accordion
        defaultExpanded={props.expanded}
        sx={borderStyle}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }}
        >
          <Grid container direction="row" alignItems="center" gap={1}>
            <Grid item>
              <DataObjectIcon
                fontSize="small"
                style={{ verticalAlign: "middle" }}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption">{props.name}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  showModal({
                    title: "Confirm Delete",
                    description: "Are you sure you want to delete this item?",
                    onYesClicked: () => {
                      props.onPropertyValueChanged(null, props.path);
                    },
                    onNoClicked: () => {},
                  });
                }}
              >
                <RemoveIcon color="error" />
              </IconButton>
            </Grid>
            <Grid item xs></Grid>
            {/* {!props.value && props.path != "$" && (
            <Grid item>
              <IconButton
                color="success"
                onClick={() => props.onPropertyAdd(props.member, props.path)}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          )}
          {props.value && props.path != "$" && (
            <Grid item>
              <IconButton color="error" onClick={() => {}}>
                <RemoveIcon />
              </IconButton>
            </Grid>
          )} */}
            {childMembersThatCanBeAdded.length > 0 && (
              <>
                <Grid item>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ minWidth: 200 }}
                  >
                    <InputLabel>Add object property</InputLabel>
                    <Select
                      value={
                        selectedObjectDefinition == null
                          ? ""
                          : JSON.stringify(selectedObjectDefinition)
                      }
                      label="Add object property"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        setselectedObjectDefinition(
                          JSON.parse(
                            e.target.value as string
                          ) as ObjectMemberDefinitionDto
                        )
                      }
                    >
                      {childMembersThatCanBeAdded.map((x, i) => {
                        return (
                          <MenuItem key={i} value={JSON.stringify(x)}>
                            {x.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <IconButton
                    color="success"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(selectedObjectDefinition, props.path);
                      props.onPropertyAdd(
                        selectedObjectDefinition!,
                        props.path
                      );
                      setselectedObjectDefinition(undefined);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box p={2}>
            {props.member.dataTypeEnum != DataTypeEnum.Object ||
              (props.value && (
                <Grid container direction="column">
                  {
                    <Grid item>
                      {props.member!.members.map((m, i) => {
                        if (props.value[m.name] != null) {
                          return (
                            <ObjectDefinitionRenderer
                              definitions={props.definitions}
                              member={m!}
                              model={props.model}
                              path={props.path}
                              onAddEntry={props.onAddEntry}
                              onRemoveEnty={props.onRemoveEnty}
                              onPropertyAdd={props.onPropertyAdd}
                              onPropertyValueChanged={
                                props.onPropertyValueChanged
                              }
                              key={i}
                            />
                          );
                        }
                      })}
                    </Grid>
                  }
                </Grid>
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
