import React, { useState } from "react";
import { ObjectDefinitionDto } from "../data-dtos/object-definition-dto";
import { DataTypeEnum } from "../data-dtos/data-type-enum";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ListIcon from "@mui/icons-material/List";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DataArrayIcon from "@mui/icons-material/DataArray";

import { InputRendererComponent } from "./input-renderer.component";
import { ObjectMemberDefinitionDto } from "../data-dtos/object-member-definition-dto";
import { ObjectDefinitionRenderer } from "./object-definition-renderer.component";
import { ObjectRendererComponent } from "./object-renderer.component";
import { useModal } from "./shared/yes-no-modal.provider";

export interface IProps {
  definitions: ObjectDefinitionDto[];
  name: string;
  member: ObjectDefinitionDto;
  values: any[];
  path: string;
  model: any;
  onAddEntry: (definition: ObjectDefinitionDto, path: string) => void;
  onRemoveEnty: (path: string, index: number) => void;
  onPropertyAdd: (objectType: ObjectMemberDefinitionDto, path: string) => void;
  onPropertyValueChanged: (val: any, path: string) => void;
}

const borderStyle = {
  border: "solid 2px rgba(255, 255, 255, 0.2)",
  borderRadius: 1,
};

export const CollectionRendererComponent: React.FC<IProps> = (props) => {
  const { showModal } = useModal();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectedValue =
    props.values.length > 0 ? props.values[selectedTabIndex] : null;

  return (
    <Box sx={borderStyle} mt={1}>
      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }}
        >
          <Grid container direction="row" alignItems="center" gap={1}>
            <Grid item>
              <ListIcon style={{ verticalAlign: "middle" }} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {props.name} ({props.values.length})
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  // addEntryToCollection(path);
                  // props.onRemoveEnty(props.path, i);
                  showModal({
                    title: "Confirm Delete",
                    description: "Are you sure you want to delete this item?",
                    onYesClicked: () => {
                      props.onPropertyValueChanged(null, props.path);
                    },
                    onNoClicked: () => {},
                  });
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid item xs></Grid>
            <Grid item>
              <IconButton
                color="success"
                onClick={(e) => {
                  e.stopPropagation();
                  // addEntryToCollection(path);
                  props.onAddEntry(props.member, props.path);
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column">
            <Grid item>
              <Tabs
                aria-label="basic tabs example"
                onChange={(e, i) => setSelectedTabIndex(i)}
                value={selectedTabIndex}
                variant="scrollable"
                scrollButtons="auto"
                // style={{ width: "inherit" }}
              >
                {(Array.isArray(props.values) ? props.values : []).map(
                  (m, i) => (
                    <Tab
                      key={i}
                      label={
                        <Grid container alignItems="center">
                          <Grid item>
                          {props.name} [{i}]
                          </Grid>
                          <Grid item>
                          <IconButton
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              // addEntryToCollection(path);

                              showModal({
                                title: "Confirm Delete",
                                description:
                                  "Are you sure you want to delete this item?",
                                onYesClicked: () => {
                                  props.onRemoveEnty(props.path, i);
                                },
                                onNoClicked: () => {},
                              });
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          </Grid>
                        </Grid>
                      }
                    />
                  )
                )}
              </Tabs>
            </Grid>
          </Grid>
          <br />
          test
          <Grid container gap={1}>
            {props.member.dataTypeEnum == DataTypeEnum.Object &&
              selectedValue != null &&
              props.member.members.map((m, mi) => {
                return (
                  <Grid item key={mi}>
                    <ObjectDefinitionRenderer
                      definitions={props.definitions}
                      member={m!}
                      model={props.model}
                      onAddEntry={props.onAddEntry}
                      onRemoveEnty={props.onRemoveEnty}
                      path={`${props.path}[${selectedTabIndex}]`}
                      onPropertyAdd={props.onPropertyAdd}
                      onPropertyValueChanged={props.onPropertyValueChanged}
                    />
                  </Grid>
                );
              })}
            {props.member.dataTypeEnum != DataTypeEnum.Object &&
              selectedValue != null && (
                <Grid item>
                  <InputRendererComponent
                    definition={props.member}
                    val={selectedValue}
                    onChange={(val) => {
                      props.onPropertyValueChanged(
                        val,
                        `${props.path}[${selectedTabIndex}]`
                      );
                    }}
                  />
                </Grid>
              )}
            {/* {props.member.dataTypeEnum != DataTypeEnum.Object && selectedValue && (
              <Grid item>
                <InputRendererComponent
                  definition={props.member}
                  val={selectedValue}
                  onChange={(val) => {
                    props.onPropertyValueChanged(
                      `${props.path}[${selectedTabIndex}]`,
                      val
                    );
                  }}
                />
              </Grid>
            )} */}
          </Grid>
          {/* <Grid container direction="column" gap={1}>
            {(Array.isArray(props.values) ? props.values : []).map(
              (v: any, i: number) => {
                return (
                  <Grid item key={i} pl={2}>
                    <Accordion disableGutters sx={{ ...borderStyle }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ flexDirection: "row-reverse" }}
                      >
                        <Grid container gap={1} alignItems="center">
                          <Grid item xs>
                            <Typography variant="subtitle2">
                              {props.member.name} [{i}]
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                // addEntryToCollection(path);

                                showModal({
                                  title: "Confirm Delete",
                                  description:
                                    "Are you sure you want to delete this item?",
                                  onYesClicked: () => {
                                    props.onRemoveEnty(props.path, i);
                                  },
                                  onNoClicked: () => {},
                                });
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container gap={1} direction="column">
                          {props.member.dataTypeEnum == DataTypeEnum.Object &&
                            props.member.members.map((m, mi) => {
                              return (
                                <Grid item key={mi}>
                                  <ObjectDefinitionRenderer
                                    definitions={props.definitions}
                                    member={m!}
                                    model={props.model}
                                    onAddEntry={props.onAddEntry}
                                    onRemoveEnty={props.onRemoveEnty}
                                    path={`${props.path}[${i}]`}
                                    onPropertyAdd={props.onPropertyAdd}
                                    onPropertyValueChanged={
                                      props.onPropertyValueChanged
                                    }
                                  />
                                </Grid>
                              );
                            })}
                          {props.member.dataTypeEnum != DataTypeEnum.Object && (
                            <Grid item>
                              <InputRendererComponent
                                definition={props.member}
                                val={v}
                                onChange={(val) => {
                                  props.onPropertyValueChanged(
                                    `${props.path}[${i}]`,
                                    val
                                  );
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Grid container direction="row" alignItems="start" gap={2}>
                    <Grid item>
                      <Typography variant="caption">
                        - {props.member.name} [{i}]
                      </Typography>
                    </Grid>
                    <Grid item>
                      {props.member.dataTypeEnum == DataTypeEnum.Object &&
                        props.member.members.map((m, mi) => {
                          return (
                            <ObjectDefinitionRenderer
                              definitions={props.definitions}
                              member={m!}
                              model={props.model}
                              onAddEntry={props.onAddEntry}
                              path={`${props.path}[${i}]`}
                              onPropertyAdd={props.onPropertyAdd}
                              key={mi}
                            />
                          );
                        })}
                      {props.member.dataTypeEnum != DataTypeEnum.Object && (
                        <>
                          <InputRendererComponent
                            definition={props.member}
                            val={v}
                            onChange={() => {}}
                          />
                          test
                        </>
                      )}
                    </Grid>
                  </Grid> 
                  </Grid>
                );
              }
            )}
          </Grid> */}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
