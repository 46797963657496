import {
  Button,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";

export interface CreateEntity {
  category: string;
  idSuggestion?: string;
  types: string[];
  onCancel: () => void;
  onCreate: (id: string, name: string, type: string) => void;
}

export const CreateEntityComponent: FC<CreateEntity> = (props) => {
  const [id, setId] = useState<string | undefined>(props.idSuggestion);
  const [name, setName] = useState<string | undefined>();
  const [selectedType, setSelectedType] = useState<number>(0);

  return (
    <Modal open onClose={() => props.onCancel()} hideBackdrop={false}>
      <Paper
        elevation={3}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "400px",
        }}
      >
        <Grid container direction="column" gap={2} p={3}>
          <Grid item xs>
            <Typography textAlign="center" variant="h6">
              Create {props.category}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Id"
              fullWidth
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Name"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          {props.types && props.types.length > 1 && (
            <Grid item>
              <Select value={selectedType} fullWidth onChange={(e) => setSelectedType(e.target.value as number)}>
                {props.types.map((e, i) => (
                  <MenuItem
                    key={i}
                    value={i}
                  >
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Button onClick={() => props.onCancel()} color="error">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Button
                    disabled={!id || !name}
                    onClick={() => props.onCreate(id!, name!, props.types[selectedType])}
                    color="success"
                    startIcon={<SaveIcon />}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};
