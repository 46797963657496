import React, { useContext, useRef } from "react";
import { ObjectDefinitionDto } from "../data-dtos/object-definition-dto";
import { DataTypeEnum } from "../data-dtos/data-type-enum";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { InputRendererComponent } from "./input-renderer.component";
import { CollectionRendererComponent } from "./collection-renderer.component";
import { ObjectMemberDefinitionDto } from "../data-dtos/object-member-definition-dto";
import jsonpath from "jsonpath";
import { ObjectRendererComponent } from "./object-renderer.component";
import { EntitiesContext } from "../pages/editor.page";

export interface IProps {
  definitions: ObjectDefinitionDto[];
  member: ObjectMemberDefinitionDto;
  path: string;
  model: any;
  onAddEntry: (definition: ObjectDefinitionDto, path: string) => void;
  onRemoveEnty: (path: string, index: number) => void;
  onPropertyAdd: (objectType: ObjectMemberDefinitionDto, path: string) => void;
  onPropertyValueChanged: (val: any, path: string) => void;
}

export const ObjectDefinitionRenderer: React.FC<IProps> = (props) => {
  const entities = useContext(EntitiesContext);

  const getValueFromModel = (path: any): any => {
    return jsonpath.query(props.model, path)[0];
    // if(val['$values']) {
    //   debugger;
    //   return val['$values'];
    // }
  };

  const md = props.definitions.find(
    (d) => d.name == props.member.objectDefinitionName
  );

  const localPath = `${props.path}.${props.member.name}`;
  const val = getValueFromModel(localPath);

  if (props.member.isCollection) {
    let values = [];
    if (val && val["$values"]) {
      values = val["$values"] as any[];
    } else values = (val as any[]) ?? [];

    return (
      <CollectionRendererComponent
        definitions={props.definitions}
        member={md!}
        name={props.member.name}
        path={localPath}
        onAddEntry={props.onAddEntry}
        onRemoveEnty={props.onRemoveEnty}
        model={props.model}
        values={values}
        onPropertyAdd={props.onPropertyAdd}
        onPropertyValueChanged={props.onPropertyValueChanged}
      />
    );
  }

  if (props.member.entityReferenceCategory && props.member.entityReferenceKey) {
    const selectedValue = entities.find(
      (x) => x.category == props.member.entityReferenceCategory && x.id == val
    );
    const dropdownValues = entities.filter(
      (x) => x.category == props.member.entityReferenceCategory
    );
    return (
      <Autocomplete
        fullWidth
        value={selectedValue}
        isOptionEqualToValue={(option, v) => option?.id == v?.id}
        onChange={(e, v) => {
          const id =
            md?.dataTypeEnum == DataTypeEnum.Number
              ? parseFloat(v?.id ?? "0")
              : v?.id;
          props.onPropertyValueChanged(id, localPath);
        }}
        options={dropdownValues}
        getOptionLabel={(option) => `${option?.name} - ${option.id}`}
        getOptionKey={(option) => option?.id}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            style={{ marginTop: 5 }}
            label={props.member.name}
          />
        )}
      />
      // <FormControl variant="standard" fullWidth sx={{ minWidth: 200 }}>
      //   <InputLabel>{props.member.entityReferenceCategory}</InputLabel>
      //   <Select
      //     value={val}
      //     label="Add object property"
      //     onChange={(e) => {}}
      //   >
      //     {entities.filter(x => x.category == props.member.entityReferenceCategory).map((x, i) => {
      //       return (
      //         <MenuItem key={x.id} value={x.id}>
      //           {x.name}
      //         </MenuItem>
      //       );
      //     })}
      //   </Select>
      // </FormControl>
    );
  }

  if (md!.dataTypeEnum == DataTypeEnum.Object) {
    return (
      <ObjectRendererComponent
        definitions={props.definitions}
        member={md!}
        model={props.model}
        name={props.member.name}
        onAddEntry={props.onAddEntry}
        onRemoveEnty={props.onRemoveEnty}
        path={localPath}
        onPropertyAdd={props.onPropertyAdd}
        onPropertyValueChanged={props.onPropertyValueChanged}
        value={val}
      />
    );
  }

  return (
    <Grid container>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">{props.member.name}</Typography>
          </Grid>
          <Grid item>
            <InputRendererComponent
              definition={md!}
              val={val}
              onChange={(val) => props.onPropertyValueChanged(val, localPath)}
            />
            {/* {renderInput(md!, val, (val) => {})}*/}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
