import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { EditorPage } from "./pages/editor.page";

import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import { LoginPage } from "./pages/login.page";
import { CssBaseline } from "@mui/material";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <CssBaseline />
        <EditorPage />
      </>
    ),
  },
  {
    path: "/login",
    element: (
      <>
        <CssBaseline />
        <LoginPage />
      </>
    ),
  }
],
{
  // Provide the base path for your app if it is hosted on a subpath (e.g., /subpath)
  basename: process.env.PUBLIC_URL || "/",
});

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
