import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

export const LoginPage = () => {
  const [subscriptionKey, setSubscriptionKey] = useState("");

  const connect = () => {
    localStorage.setItem("subscription-key", subscriptionKey);
    (window as any).location = "/";
  };

  return (
    <>
      <form>
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "400px",
          }}
        >
          <Box p={2}>
            <Grid container gap={2}>
              <Grid item xs={12} mb={5}>
                <Typography variant="h5" textAlign="center">
                  Login
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="subscription-key"
                  fullWidth
                  type="password"
                  autoComplete="new-password"
                  value={subscriptionKey}
                  onChange={(e) => setSubscriptionKey(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => connect()} fullWidth variant="contained">
                  Connect
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </form>
    </>
  );
};
