import React from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Modal, Paper } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

export interface YesNoModalProps {
    title: string;
    description: string;
    onYesClicked: () => void;
    onNoClicked: () => void;
}

export const YesNoModalComponent: React.FC<YesNoModalProps> = (props) => {

    return <Modal open onClose={() => props.onNoClicked()} hideBackdrop={false}>
        <Paper
            elevation={3}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
            <Card>
                <CardHeader title={
                    <Grid container justifyContent="center" alignItems="center" gap={1}>
                        <Grid item>
                            <ErrorIcon fontSize="large" color="warning" />
                        </Grid>
                        <Grid item>
                            {props.title}
                        </Grid>
                    </Grid>}>
                </CardHeader>
                <CardContent>
                    {props.description}
                </CardContent>
                <CardActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button color="primary" variant="outlined" onClick={() => props.onNoClicked()}>No</Button>
                        </Grid>
                        <Grid item>
                            <Button color="error" variant="contained" onClick={() => props.onYesClicked()}>Delete</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Paper>
    </Modal>;
};