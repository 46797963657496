
// Helper function to handle response and errors
const handleResponse = async (response: Response) => {
  // let starTime = Date.now();
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || "Unknown error occurred");
  }

  try {
    return await response.json();
  }
  catch {
    return null;
  }
  finally {
    // console.log(Date.now() - starTime, "Parsing response")
  }
};

// GET request
const get = async <T>(endpoint: string): Promise<T> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Subscription-Key": localStorage.getItem("subscription-key") ?? ""
    },
  });

  if(response.status == 401) 
  {
    (window as any).location = "/login";
  }

  return handleResponse(response);
};

// POST request
const post = async <T>(endpoint: string, body: any): Promise<T> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Subscription-Key": localStorage.getItem("subscription-key") ?? ""
    },
    body: JSON.stringify(body),
  });

  if(response.status == 401) 
  {
    (window as any).location = "/login";
  }

  return handleResponse(response);
};

export interface PlunderDataEntityDto
{
  category: string;
  key: string;
  details: string;
}

export { post, get };
